import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ApiNames from "../Constants/ApiNames";
import UserContext from "../Utils/User_Context";
import tagManagerEvents from "../Utils/GoogleTagManager";

import { fetchGet, fetchPost } from "../Utils/FetchApi";
import { encode as base64_encode } from "base-64";
import LandingThree from "../Components/LandingThree";
import { Service_key, Script_url, He_Url } from "../Utils/Config";

let encodedMsisdn = "";
let uniqueId = "";

let adNetwork = "";
let _Otp = "";

const LandingScreenTwo = () => {
  const userContext = useContext(UserContext);
  const [phoneNo, setPhoneNo] = useState("");
  const [otp, setOTP] = useState("");
  const [userExist, setUserExist] = useState(false);
  const [userPackage, setUserPackage] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [heUser, setHeUser] = useState(false);

  const [loader, setLoader] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const search = useLocation().search;
  const utm_source = new URLSearchParams(search).get("utm_source");

  useEffect(() => {
    if (userContext.token) {
      uniqueId = generateUUID();
      console.log(uniqueId);
      injectScript(
        document,
        "script",
        Script_url,
        "uniqid",
        Service_key,
        uniqueId
      );
      fetchHeUrl();
      checkUtm();
    }
  }, [userContext.token]);

  const generateUUID = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };

  const injectScript = (s, o, u, r, k, key) => {
    const b = window.location.href;
    let v = b
      .substr(b.indexOf(r))
      .replace(r + "=", "")
      .toString();
    r = v.indexOf("&") !== -1 ? v.split("&")[0] : v;

    const a = document.createElement(o);
    const m = document.getElementsByTagName(o)[0];

    a.async = true;
    a.setAttribute("crossorigin", "anonymous");

    a.src = `${u}script.js?ak=${k}&lpi=${r}&lpu=${encodeURIComponent(
      b
    )}&key=${key}`;

    m.parentNode.insertBefore(a, m);
  };

  //   Auth Flow Api
  async function fetchHeUrl() {
    try {
      const url = He_Url;
      const response = await fetch(url);
      console.log("1" + uniqueId);

      const data = await response.json();
      // const data = {
      //   data: {
      //     message: "Success",
      //     status: 1,
      //     response_code: 0,
      //     otp: "8616",
      //     msisdn: "03098765656",
      //   },
      //   status: 1,
      //   message: "0k",
      //   responseCode: 0,
      // };

      if (data.status === 1 && data.data.msisdn) {
        let phoneNumber = data.data.msisdn.slice(-10);
        if (data.data.response_code === 102) {
          navigatTohome(phoneNumber);
        }
        encodedMsisdn = phoneNumber;
        setPhoneNo(phoneNumber);
        setOTP(data.data.otp);
        _Otp = data.data.otp;
        setOpenModal(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      authFlow();
    }
  }
  const authFlow = async () => {
    try {
      let response = await fetchGet(
        ApiNames.GetFlow +
          "?utm_source=" +
          utm_source +
          "&msisdn=" +
          encodedMsisdn,
        userContext.token
      );
      if (response.status === 0) {
        headerEnrichment(response.result);
      }
    } catch (err) {}
  };

  const headerEnrichment = (response) => {
    try {
      if (response.isSpecialFlow === false) {
        if (response.msisdn) {
          if (response.signIn) {
            // user is active
            navigatTohome(response.msisdn);
          }
          // he case in which we recive msisdn from api
          // setPhoneNo(response.msisdn);
          setUserPackage(response.subPackage);

          setHeUser(true);
          tagManagerEvents("heUser", utm_source);
        } else {
          tagManagerEvents("wifiUser", utm_source);
        }
      } else if (response.isSpecialFlow === true) {
        setOpenModal(true);

        setTimeout(() => {
          setLoader(true);
          subscriberUser(ApiNames.subscribeWeb, _Otp, encodedMsisdn);
        }, 3000);
      }
    } catch (error) {}
  };
  const userSignup = async (msisdn) => {
    let data = {
      msisdn: msisdn,
      sourceInfo: "",
      os: "",
      gameId: "",
      uniqueId: uniqueId,
    };
    try {
      let response = await fetchPost(ApiNames.signup, userContext.token, data);
      if (response.status === 1) {
        setErrorMsg(
          "Service is only for Jazz customers. Kindly enter a working Jazz number."
        );
      } else {
        if (
          response.status === 0 &&
          response.result.toLowerCase() === "success"
        ) {
          if (response.exists) {
            navigatTohome(msisdn);
          }
          setUserExist(response.exists);
          setOpenModal(true);
        } else {
          setErrorMsg("Unable to send OTP, Please try again.");
        }
      }
    } catch (err) {
      setErrorMsg("Something went wrong, Please try again.");
    }
  };
  const checkUtm = async () => {
    try {
      let response = await fetchGet(ApiNames.utm, userContext.token);
      if (response.status === 200) {
        {
          response.response.map((e) => {
            if (utm_source === e.sf_utm) {
              adNetwork = e.ad_network;
            }
          });
        }
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };
  const subscriberUser = async (apiName, otp, msisdn) => {
    try {
      let data = {
        msisdn: msisdn,
        nick: "GW-guestUser",
        otp: otp,
        utm_source: utm_source,
        subPackage: userPackage,
        gameId: null,
        uniqueId: uniqueId,
      };

      let response = await fetchPost(apiName, userContext.token, data);
      if (response.status === 0) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          tagManagerEvents("signup", "optimusTikTok");
        } else if (adNetwork.toLowerCase() === "google") {
          tagManagerEvents("signup", "optimus");
        } else {
          tagManagerEvents("signup", utm_source);
        }
        setLoader(false);
        navigatTohome(msisdn);
      } else {
        tagManagerEvents("signupFailed", utm_source);
        setErrorMsg("Unable to subscribe user, Please try again.");
        setLoader(false);
      }
    } catch (err) {
      tagManagerEvents("signupFailed", utm_source);
      setErrorMsg("Something went wrong, Please try again.");
      setLoader(false);
    }
  };

  const login = async () => {
    let data = {
      msisdn: phoneNo,
    };
    try {
      let response = await fetchPost(
        ApiNames.loginWeb,
        userContext.token,
        data
      );
      if (response.status === 0) {
        navigatTohome(phoneNo);
        setLoader(false);
      } else {
        setErrorMsg("Unable to subscribe user, Please try again.");
        setLoader(false);
      }
    } catch (err) {
      setErrorMsg("Something went wrong, Please try again.");
      setLoader(false);
    }
  };

  //   // -------------------------------------------------- function call --------------------------------------------------

  const onTextChange = (e) => {
    setErrorMsg("");
    let value = e.target.value;
    if (value === "0" || !value.startsWith(3)) {
      setPhoneNo("");
    } else {
      let numRegex = new RegExp("^[0-9]*$");
      if (numRegex.test(value)) {
        setPhoneNo(value);
        if (!heUser) {
          if (value.length === 10) {
            userSignup(value);
          }
        }
      }
    }
  };

  const handleChange = (otp) => {
    setOTP(otp);
  };

  const onPressNext = () => {
    try {
      setLoader(true);
      if (userExist) {
        login();
      } else {
        subscriberUser(ApiNames.subscribeWeb, otp, phoneNo);
      }
    } catch (error) {
      console.log("error :: ", error);
    }
  };

  const navigatTohome = (phoneNo) => {
    window.location.replace(`https://gamenowplay.com/?q=${phoneNo}`);
    // let url = window.location.origin;
    // let encodedMsisdn = base64_encode(phoneNo);
    // url = `${url}?user=${encodedMsisdn}`;
    // if (url.includes("http:")) {
    //   url = url.replace("http", "https");
    // }
    // window.location.replace(url);
  };

  return (
    <>
      <LandingThree
        phoneNo={phoneNo}
        OTP={otp}
        onTextChange={onTextChange}
        handleChange={handleChange}
        errorMsg={errorMsg}
        onPressNext={onPressNext}
        loader={loader}
        openModal={openModal}
      />
    </>
  );
};

export default LandingScreenTwo;
