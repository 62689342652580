import React from "react";
import "../style/subscription.css";
import Logo from "../Assets/Images/new-landing-logo.webp";
import cardImg from "../Assets/Images/main-img.webp";
import vr from "../Assets/Images/vr.webp";
import gamecontroller from "../Assets/Images/game-controller.webp";
import OTPInput, { ResendOTP } from "otp-input-react";
import gamecharacters from "../Assets/Images/game-characters.webp";
import mbmainimg from "../Assets/Images/mb-main-img.webp";

const LandingThree = (props) => {
  function keyFunction(event) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const validChars = /^[0-9]+$/;

    if (!validChars.test(keyValue)) {
      event.preventDefault();
    }
  }
  return (
    <div>
      <div className="animated-bg">
        <div className="otpouterDiv outerDivMobile">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="new-logo paralogo">
                  <img src={Logo} />
                </div>

                {/* <Navbar /> */}

                {/* ------------------------------- Enter Ph number -------------------------------  */}

                <div className="newParamWraper">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="subOuterbox">
                        <div className="dis-mobile">
                          <img src={mbmainimg} alt="" />
                        </div>

                        <div className="subscribeBox">
                          <div className="absoluteImgs">
                            <img
                              src={gamecharacters}
                              className="gamecharacters"
                              alt="img"
                            />
                            <img
                              src={gamecontroller}
                              className="gamecontroller"
                              alt="img"
                            />
                            <img src={vr} className="vr-img" alt="img" />
                          </div>

                          <div
                            className="round-gradient"
                            style={{ height: "96.5%" }}
                          ></div>
                          <div className="subinnerBox newparam">
                            <div className="subscriptionText">
                              {/* <h3>
                                Subscribe &amp; Win <br />
                                Rs 10,000
                              </h3> */}

                              <div className="cardImg">
                                <img src={cardImg} alt="main img" />
                              </div>

                              <p className="getotp">
                                Please input number to get OTP
                              </p>
                            </div>

                            <div>
                              <div>
                                <div className="otpinput-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      +92
                                    </span>
                                  </div>
                                  <input
                                    type="tel"
                                    autoComplete="off"
                                    className="cform-control rounded tel-field"
                                    style={{ color: "white" }}
                                    placeholder="XXX - XXX - XXXX"
                                    value={props.phoneNo}
                                    onChange={props.onTextChange}
                                    maxLength="10"
                                    onKeyPress={keyFunction}
                                    pattern="3[0-9]{9}"
                                  />
                                </div>
                                {/* <p className="subs-error">{props.errorMsg}</p> */}
                              </div>
                              {/* otp */}
                              {props.openModal ? (
                                <>
                                  <div className="enterotptext">
                                    Please enter OTP received via SMS
                                  </div>
                                  <div className="otpInput">
                                    <OTPInput
                                      className="input-fields"
                                      value={props.OTP}
                                      onChange={(value) =>
                                        props.handleChange(value)
                                      }
                                      autoFocus
                                      OTPLength={4}
                                      otpType="number"
                                      disabled={false}
                                    />
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {props.errorMsg && (
                                <p className="subs-error">{props.errorMsg}</p>
                              )}

                              {/* otp  */}

                              {/* <div className="resend-otp">
                                {props.showCounter ? (
                                  <p className="">
                                    {" "}
                                    Didn’t Receive OTP?{" "}
                                    <div
                                      className="m-flex"
                                      style={{ justifyContent: "center" }}
                                    >
                                      <span> 00:{props.seconds}</span>
                                    </div>
                                  </p>
                                ) : (
                                  <p>
                                    {" "}
                                    Didn’t Receive OTP?{" "}
                                    <span
                                      onClick={() => props.onResendOtpPressed()}
                                    >
                                      Resend
                                    </span>
                                  </p>
                                )}
                              </div> */}

                              <div className="input-group-append">
                                {props.loader ? (
                                  <>
                                    {" "}
                                    <span className="otpBtn">Loading</span>
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    {props.OTP.length === 4 ? (
                                      <span
                                        className="otpBtn"
                                        onClick={props.onPressNext}
                                      >
                                        Buy Now
                                      </span>
                                    ) : (
                                      <span className="otpdisabled">
                                        Buy Now
                                      </span>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>

                            <p className="taxpermonth">
                              Service Charges : Rs. 8.37+T/Day.
                            </p>

                            <div className="links">
                              <a href="/privacy-policy" target="_blank">
                                Privacy Policy
                              </a>
                              <span></span>
                              <a href="/terms-condition" target="_blank">
                                Terms &amp; Conditions
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingThree;
